//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LeftMenu from '@/components/common/left-menu'
import SwitchUser from '@/components/common/switch-user'
import {imageMixin} from '@/mixins/imageMixin'
import {mapGetters, mapState} from 'vuex'
import {adminCodes} from '@/enums/adminSettings'
export default {
  name: 'NavigationBars',
  components: {LeftMenu, SwitchUser},
  mixins: [imageMixin],
  data() {
    return {
      clipped: true,
      fixed: true,
      miniVariant: true,
      right: true,
      switchUserDialog: false,
      backofficeFooterContent: '',
    }
  },
  computed: {
    ...mapState('shoppingCart', ['cartItemCount']),
    ...mapState('common/companyDetails', ['companyDetails']),
    ...mapState('common/userProfile', ['userDetails']),
    ...mapGetters('backoffice/settings', ['adminSettingByCode']),
    showBackofficeFooter() {
      const showBackofficeFooter = this.adminSettingByCode(
        adminCodes.ShowBackofficeFooter
      )
      return showBackofficeFooter && showBackofficeFooter.value === 'true'
    },
    loggedIn() {
      return this.$auth.loggedIn
    },
    customerName() {
      return this.$auth.user != null ? this.$auth.user.fullname : ''
    },
    showSwitchUser() {
      return this.$auth.user.userTypeCode === 'SUPER'
    },
    showAllCustomers() {
      return this.$store.state.backoffice.settings.adminSettings
        .show_all_customers_for_super_admin_users
        ? this.$store.state.backoffice.settings.adminSettings
            .show_all_customers_for_super_admin_users.value === 'true'
        : false
    },
  },
  async mounted() {
    if (this.showBackofficeFooter) {
      await this.getFooter()
    }
  },
  methods: {
    openSignInDialog() {
      this.$router.push('/login')
    },
    profile() {
      this.$router.push('/backoffice/my-profile')
    },
    signOut() {
      this.$router.push('/logout')
    },
    updateMiniVariant(value) {
      this.miniVariant = value
    },
    showSwitchUserDialog() {
      this.switchUserDialog = true
    },
    closeSwitchUserDialog() {
      this.switchUserDialog = false
    },
    async getFooter() {
      const footer_data = (await this.$axios.get(`/contents/Backoffice-footer`)).data
      if (footer_data.details && footer_data.details.text && footer_data.details.text.fieldValue && footer_data.details.text.fieldValue.value) {
        this.backofficeFooterContent = footer_data.details.text.fieldValue.value
      }
    }
  },
}
