//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavigationBars from '@/components/common/navigation-bars'
import {mapActions, mapGetters} from 'vuex'
import Confirm from '@/components/common/confirm'
import { LiveChatWidget } from '@livechat/widget-vue/v2'
export default {
  components: {Confirm, NavigationBars,LiveChatWidget},
  data() {
    return {
      clipped: true,
      drawer: false,
      fixed: false,
      miniVariant: true,
      right: true,
      rightDrawer: false,
      chatId: this.$config.liveChatId && this.$config.liveChatId.toString(),
    }
  },
  head() {
    const head = {
      ...this.$nuxtI18nHead({
        addSeoAttributes: true,
      }),
    }
    return head
  },
  computed: {
    ...mapGetters(['loading']),
    passwordMatch() {
      return () =>
        this.registerForm.password === this.registerForm.verify ||
        'Password must match'
    },
    loggedIn() {
      return this.$auth.loggedIn
    },
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open
  },
  methods: {
    ...mapActions('backoffice/settings', ['fetchFields']),
    openSignInDialog() {
      this.signInDialog = true
    },
    async signIn() {
      if (this.$refs.signInForm.validate()) {
        try {
          const response = await this.$auth.loginWith('local', {
            data: {
              username: this.signInForm.data.email,
              password: this.signInForm.data.password,
            },
          })
          if (response.status === 200 && response.data.token) {
            this.$toast.success('Logged in!')
            this.closeSignInDialog()
            // await this.fetchFields()
            if (this.$route.path === '/') {
              setTimeout(() => {
                window.location.href = window.location.href
              }, 250)
            }
          } else {
            this.$toast.error('Invalid username or password!')
          }
        } catch (err) {
          this.$toast.error('Invalid username or password!')
        }
      }
    },
    register() {
      if (this.$refs.signInForm.validate()) {
        // this.$auth.loginWith()
      }
    },
    profile() {},
    signOut() {
      this.$router.push('/logout')
    },
    closeSignInDialog() {
      if (this.$refs.signInForm) {
        this.$refs.signInForm.reset()
        this.$refs.signInForm.resetValidation()
      }
      if (this.$refs.registerForm) {
        this.$refs.registerForm.reset()
        this.$refs.registerForm.resetValidation()
      }
      this.signInDialog = false
    },
  },
}
